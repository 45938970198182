import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import EmailCapture from "../components/emailCatpure"
// import EmailRedirect from "../components/emailFormRedirect"

// const IndexPage = () => (

class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalContacts: 250,
      requiredSrcNums: 2,
      totalSmsParts: 2,
      totalMmsParts: 0,
      contactsPerSrcNum: 150,
      pricePerSmsPart: 0.05,
      pricePerMmsPart: 0.08,
      pricePerSrcNum: 2,
      isCalcOpen: true,
      openFaqId: "",
    }
  }

  handleChange(e) {
    // console.log(`${e.target.name}: ${e.target.value}`)
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {}

  faqArrow(faqId) {
    // console.log("faqArrow: ", faqId)
    if (this.state.openFaqId !== faqId) {
      // console.log("faqArrow nomatch: ", faqId)
      return <i className="material-icons faqIcon">keyboard_arrow_down</i>
    } else {
      // console.log("faqArrow match: ", faqId)
      return <i className="material-icons faqIcon tgrey3">keyboard_arrow_up</i>
    }
  }

  toggleCalc() {
    const calc = document.getElementById("toggleCalc")
    if (!calc) return
    let calcClass = calc.className
    if (calcClass.indexOf("openCalc") !== -1) {
      calcClass = calcClass.replace(" openCalc", "")
    } else {
      calcClass = calcClass + " openCalc"
    }
    calc.className = calcClass
  }

  render() {
    return (
      <Layout>
        <SEO title="Pricing" />

        {/* HERO */}
        <div className="hero1">
          <div className="content taCenter">
            <h1>Pricing</h1>
            <p>
              Fair and Affordable Usage Based Pricing{" "}
              <span role="img" aria-label="thumb-up">
                👍
              </span>
            </p>
          </div>
        </div>

        {/* PRICING INFO & CALC */}
        <div className="pricing">
          <div className="content">
            {/* <h1>Pricing</h1>
            <h2>Simple and Affordable</h2>
            <br /> */}
            <div className="flexContainer">
              <div className="flexCard">
                {/* COST CALCULATOR */}
                <div id="toggleCalc" className={`card defaultCalc`}>
                  <div
                    className="cardSection taCenter pointer"
                    onClick={() => this.toggleCalc()}
                    // onClick={e =>
                    //   this.setState({ isCalcOpen: !this.state.isCalcOpen })
                    // }
                  >
                    <h1>
                      Cost Calculator{" "}
                      <i className="material-icons calcIconClosed">
                        keyboard_arrow_down
                      </i>
                      <i className="material-icons calcIconOpen">
                        keyboard_arrow_up
                      </i>
                      {/* {!this.state.isCalcOpen && (
                        <i className="material-icons calcIconOpen">keyboard_arrow_down</i>
                      )}
                      {this.state.isCalcOpen && (
                        <i className="material-icons calcIconClosed">keyboard_arrow_up</i>
                      )} */}
                    </h1>
                  </div>
                  {/* {this.state.isCalcOpen && ( */}
                  <div className="calcBody">
                    <div className="cardSection">
                      <h2>Contacts List:</h2>
                      <div>
                        <input
                          id="totalContacts"
                          name="totalContacts"
                          type="number"
                          value={this.state.totalContacts}
                          min="1"
                          max="50000"
                          onChange={e => this.handleChange(e)}
                        />
                        &nbsp; Contacts
                      </div>
                      {/* <h3>
                          {Math.ceil(
                            this.state.totalContacts /
                              this.state.contactsPerSrcNum
                          )}{" "}
                          Source Numbers Required <br />(
                          {this.state.contactsPerSrcNum} Contacts per Source
                          Number)
                        </h3> */}
                    </div>
                    {/* <div className="cardSection">
            <h2>Total Source Numbers:</h2>
            <div>10 Required Numbers</div>
            <h3>150 Contacts per Source Number</h3>
          </div> */}
                    <div className="cardSection">
                      <h2>Message Length:</h2>

                      <div>
                        <input
                          id="totalSmsParts"
                          name="totalSmsParts"
                          value={this.state.totalSmsParts}
                          onChange={e => this.handleChange(e)}
                          type="number"
                          min="1"
                          max="10"
                        />
                        &nbsp; SMS Parts
                      </div>
                      {/* <h3>
                          Broadcast message length is usually 2-3 SMS Parts for
                          most customers.
                        </h3> */}
                      <h3 style={{ margin: "8px 0px 0px" }}>
                        Most broadcasts are 2-3 SMS Parts.
                      </h3>
                    </div>
                    <div className="cardSection">
                      <h2>Picture Messages:</h2>

                      <div>
                        <input
                          id="totalMmsParts"
                          name="totalMmsParts"
                          value={this.state.totalMmsParts}
                          onChange={e => this.handleChange(e)}
                          type="number"
                          min="0"
                          max="10"
                        />
                        &nbsp; MMS Parts
                      </div>
                      {/* <h3>
                          Broadcast message length is usually 0-1 MMS (Picture
                          Message) Parts for most customers.
                        </h3> */}
                    </div>
                    <div className="cardSection bb0">
                      <h2>Total:</h2>

                      <div className="mb8">
                        $
                        {(
                          this.state.totalContacts *
                          this.state.totalSmsParts *
                          this.state.pricePerSmsPart
                        ).toFixed(2)}{" "}
                        <span className="fw400 tgrey3">for</span>{" "}
                        {this.state.totalContacts * this.state.totalSmsParts}{" "}
                        SMS
                      </div>
                      {this.state.totalMmsParts > 0 && (
                        <div className="mb8">
                          $
                          {(
                            this.state.totalContacts *
                            this.state.totalMmsParts *
                            this.state.pricePerMmsPart
                          ).toFixed(2)}{" "}
                          <span className="fw400 tgrey3">for</span>{" "}
                          {this.state.totalContacts * this.state.totalMmsParts}{" "}
                          MMS
                        </div>
                      )}

                      <div>
                        $
                        {(
                          Math.ceil(
                            this.state.totalContacts /
                              this.state.contactsPerSrcNum
                          ) * this.state.pricePerSrcNum
                        ).toFixed(2)}{" "}
                        <span className="fw400 tgrey3">for</span>{" "}
                        {Math.ceil(
                          this.state.totalContacts /
                            this.state.contactsPerSrcNum
                        )}{" "}
                        Source Numbers
                      </div>
                      <h3 style={{ margin: "6px 0px 0px" }}>
                        ({this.state.contactsPerSrcNum} Contacts per Source
                        Number)
                      </h3>
                      {/* <h3 className="mb0">(150 Contacts per Source Number)</h3> */}
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </div>
              <div className="flexCard">
                {/* PRICES LIST */}
                <div className="card pb0">
                  <div className="cardSection taCenter">
                    <h1>Starting From</h1>
                    {/* {!this.state.isCalcOpen && <h1>Starting From</h1>}
                    {this.state.isCalcOpen && <h1>Total Cost</h1>} */}
                    <p className="mb8">
                      {/* <span>$49.00</span> CAD */}
                      <span>
                        $
                        {(
                          this.state.totalContacts *
                            this.state.totalSmsParts *
                            this.state.pricePerSmsPart +
                          this.state.totalContacts *
                            this.state.totalMmsParts *
                            this.state.pricePerMmsPart +
                          Math.ceil(
                            this.state.totalContacts /
                              this.state.contactsPerSrcNum
                          ) *
                            this.state.pricePerSrcNum
                        ).toFixed(2)}
                      </span>{" "}
                      CAD
                    </p>
                    <p className="m0">Per Broadcast +HST</p>
                  </div>

                  {/* <div className="cardSection ">
            <p>Usage Based Pricing</p>
            <p>Starting</p>
            <p>
              <i className="material-icons fs20">wifi_tethering</i> &nbsp; $49 CAD
              minimum
            </p>
            <p>
              <i className="material-icons fs20">record_voice_over</i> &nbsp; $49
              CAD per Broadcast minimum
            </p>
          </div> */}

                  <div className="cardSection priceList">
                    {/* <p>Fair Usage Based Pricing</p> */}
                    {/* <p>
              <i className="material-icons fs20">wifi_tethering</i> &nbsp; $49 CAD
              minimum broadcast fee
            </p> */}
                    {/* <p>
              <i className="material-icons fs20">record_voice_over</i> &nbsp; $49
              CAD per Broadcast minimum
            </p> */}
                    <p>
                      <i className="material-icons fs20">chat</i> &nbsp; $0.05
                      CAD per SMS Part
                    </p>
                    <p>
                      <i className="material-icons fs20">photo</i> &nbsp; $0.08
                      CAD per MMS (Picture Message)
                    </p>
                    <p>
                      <i className="material-icons fs20">phone</i> &nbsp; $2.00
                      CAD per Source Number
                    </p>
                  </div>

                  <div className="cardSection bb0">
                    <div className="taCenter">
                      <EmailCapture />
                      {/* <input
                        type="email"
                        name=""
                        placeholder="your.name@email.com"
                        style={{
                          marginTop: "8px",
                          marginBottom: "16px",
                          width: "100%",
                          maxWidth: "100%",
                          padding: "8px 12px",
                          fontWeight: "400",
                        }}
                      />
                      <br />
                      <button
                        className=""
                        style={{
                          padding: "12px 20px",
                        }}
                      >
                        Get Started
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* HERO FOOTER*/}
        <div className="heroFooter taCenter">
          <div className="content">
            <h1>Grow Your Sales </h1>
            {/* <p>
          Share your contact lists. Collaborate on message. We take care of the
          rest!
        </p> */}
            <p>Reach the customers that matter most, your own.</p>
            <EmailCapture />
            {/* <p style={{ padding: "0px 20px" }}>
              <input type="email" name="" placeholder="your.name@email.com" />
              <br />
              <button className="">Get Started</button>
              <button className="">Yes, Contact Me</button>
              <button className="">Yes, Contact Me to Get Started</button>
            </p> */}
          </div>
        </div>
      </Layout>
    )
  }
}

// module.exports = {
//   IndexPage: IndexPage,
// }

export default PricingPage
